import { useAuth } from '@/hooks';

import { transformAccessesToNavItems } from './nav.utils';
import NavHeader from './subcomponents/NavHeader';
import NavList from './subcomponents/NavList';

const Nav = () => {
  const { user } = useAuth();

  return (
    <div className="fixed z-10 flex h-full w-66 flex-col gap-2 bg-primary lg:z-50">
      <NavHeader />
      <NavList navItems={transformAccessesToNavItems(user?.accesses)} />
    </div>
  );
};

export default Nav;
