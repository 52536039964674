export const RAILS_URL =
  import.meta.env.VITE_RAILS_URL || 'https://app.splashbrandsdev.com/';
export const API_URL =
  import.meta.env.VITE_API_URL || 'https://app.splashbrandsdev.com/manage/';

// SENTRY
export const SENTRY_AUTH_TOKEN = import.meta.env.VITE_SENTRY_AUTH_TOKEN || '';
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN || '';
export const SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT || '';
export const SENTRY_ENABLED =
  SENTRY_ENVIRONMENT === 'development' ||
  SENTRY_ENVIRONMENT === 'production' ||
  SENTRY_ENVIRONMENT === 'staging';

// DEBUGGING
export const MOCKS_ENABLED = import.meta.env.VITE_MOCKS_ENABLED === 'true';

// FEATURE FLAGS, eg. example: 'example_1'
export const FEATURE_FLAGS = {
  cartRulesBuyXGetYDash: 'cart_rules_buy_x_get_y_dash',
  permaDeveloperMode: 'perma_developer_mode',
  specialComponentsDash: 'special_components_dash'
};
