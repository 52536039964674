import { useLocation, useMatch } from 'react-router-dom';

import Link from '@/components/Link';
import { FEATURE_FLAGS } from '@/environment';
import { useFeatureFlags } from '@/hooks';
import { t } from '@/translations/i18n';

import type { CategoryItemProps } from '../nav.types';

const categoryFeatureFlag = {
  special_components: 'specialComponentsDash'
};

const CategoryItem = ({ depth, tName, path, subpaths }: CategoryItemProps) => {
  const features = useFeatureFlags();
  const { pathname } = useLocation();

  const categoryItemName = t(`nav.${tName}`);

  const pathMatches = useMatch({ path, end: false });
  const subpathMatches = subpaths?.some(subpath => pathname.includes(subpath));

  const navFeatureEnabled =
    features[
      categoryFeatureFlag[
        tName as keyof typeof categoryFeatureFlag
      ] as keyof typeof FEATURE_FLAGS
    ];
  const categoryEnabled = tName ? (navFeatureEnabled ?? true) : false;

  return categoryEnabled ? (
    <li className={depth === 2 ? 'h-[37.36px]' : 'h-[43.53px]'}>
      <Link
        className={getBaseClasses(depth, !!(subpathMatches || pathMatches))}
        redirectTo={path}
      >
        {categoryItemName}
      </Link>
    </li>
  ) : (
    <></>
  );
};

export default CategoryItem;

const getBaseClasses = (depth: CategoryItemProps['depth'], match: boolean) =>
  `flex py-2 whitespace-nowrap pr-6 transition-colors duration-300 ease-out hover:text-linkHover ${
    depth === 1 ? 'text-[15px] pl-12 pt-[9px] -translate-x-1' : 'pl-19'
  } ${match ? 'text-white' : 'text-tertiary'}`;
