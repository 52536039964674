import useSWRImmutable from 'swr/immutable';

import { FEATURE_FLAGS } from '@/environment';
import server from '@/services/server';
import { snakeToCamelCase } from '@/utils/caseConverter';

type FeatureFlag = {
  key: string;
  state: boolean;
};

type TransformedFeatureFlags = {
  [key in keyof typeof FEATURE_FLAGS]: boolean;
};

const mapData = (features: FeatureFlag[]) => {
  const transformedFeatures: Record<string, boolean> = {};

  features.forEach(({ key, state }) => {
    transformedFeatures[snakeToCamelCase(key) as string] = state;
  });

  return transformedFeatures;
};

const useFeatureFlags = () => {
  const getFeatureFlags = async () => {
    try {
      const { features } = await server.get({ path: 'features', version: 1 });
      return mapData(features);
    } catch (e) {
      return {};
    }
  };

  const { data } = useSWRImmutable('featureFlags', getFeatureFlags);

  return (data ?? {}) as TransformedFeatureFlags;
};

export default useFeatureFlags;
